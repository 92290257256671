import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';

export const Wrapper = styled(Stack)(({ theme }) => ({
  boxShadow: '0 0 30px 0 rgba(82,63,105,.05)',
  border: 0,
  background: theme.palette.common.white,
  padding: '12px 16px',
  borderRadius: '12px',
}));

export const Header = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  padding: '6px 0',
  marginBottom: '16px',
});
