export const LOCATION_US = [
  {
    _id: '60c3bb09ca42bf5a48232c69',
    segmentation: 'US-MI',
    description: 'HGreg Doral',
    address: '8505 NW 12th St, Doral, FL, USA, 33126',
    address1: '8505 NW 12th St',
    city: 'Doral',
    state: 'FL',
    country: 'USA',
    postalCode: '33126',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP4450',
  },
  {
    _id: '616ed28f40d2c6df13deb814',
    segmentation: 'US-OR',
    description: 'HGreg Orlando',
    address: '2510 Jetport Drive,  Orlando, FL, USA, 32809',
    address1: '2510 Jetport Drive',
    city: 'Orlando',
    state: 'FL',
    country: 'USA',
    postalCode: '32809',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP19417',
  },
  {
    _id: '616ed28f40d2c6df13deb815',
    segmentation: 'US-MIA',
    description: 'HGreg Miami',
    address: '8101 NW 7 Avenue, Miami, FL, USA, 33150',
    address1: '8101 NW 7 Avenue',
    city: 'Miami',
    state: 'FL',
    country: 'USA',
    postalCode: '33150',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP20994',
  },
  {
    _id: '616ed28f40d2c6df13deb816',
    segmentation: 'US-WP',
    description: 'HGreg West Palm',
    address: '551 S Military Trail, West Palm, FL, USA, 33415',
    address1: '551 S Military Trail',
    city: 'West Palm Beach',
    state: 'FL',
    country: 'USA',
    postalCode: '33415',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP21674',
  },
  {
    _id: '616ed28f40d2c6df13deb817',
    segmentation: 'US-DE',
    description: 'HGreg Nissan Delray',
    address: '2200 S Federal Hwy,  Nissan Delray, FL, USA, 33483',
    address1: '2200 S Federal Hwy',
    city: 'Delray Beach',
    state: 'FL',
    country: 'USA',
    postalCode: '33483',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP13821',
  },
  {
    _id: '616ed28f40d2c6df13deb818',
    segmentation: 'US-KE',
    description: 'HGreg Nissan Kendal',
    address: '17305 South Dixie Highway, Nissan Kendal, FL, USA, 33157',
    address1: '17305 South Dixie Highway',
    city: 'Palmetto Bay',
    state: 'FL',
    country: 'USA',
    postalCode: '33157',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP21930',
  },
  {
    _id: '616ed28f40d2c6df13deb819',
    segmentation: 'US-BP',
    description: 'HGreg Nissan Buena Park',
    address: '6501 Auto Center Dr, Nissan Buena Park, FL, USA, 90621',
    address1: '6501 Auto Center Dr',
    city: 'Buena Park',
    state: 'FL',
    country: 'USA',
    postalCode: '90621',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP24216',
  },
  {
    _id: '616ed28f40d2c6df13deb81a',
    segmentation: 'US-PH',
    description: 'HGreg Nissan Puente Hills',
    address: '17320 Gale Ave, Nissan Puente Hills, FL, USA, 91748',
    address1: '17320 Gale Ave',
    city: 'City of Industry',
    state: 'FL',
    country: 'USA',
    postalCode: '91748',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP24217',
  },
  {
    _id: '616ed28f40d2c6df13deb81b',
    segmentation: 'US-IN',
    description: 'HGreg Infiniti Monrovia',
    address: '821 E Central Ave,  Infiniti Monrovia, CA, USA, 91016',
    address1: '821 E Central Ave',
    city: 'Monrovia',
    state: 'CA',
    country: 'USA',
    postalCode: '91016',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP23440',
  },
  {
    _id: '616ed28f40d2c6df13deb81c',
    segmentation: 'US-TM',
    description: 'Hgreg Tampa',
    address: '1207 boul. Brandon Est, Tampa, FL, USA, 33511',
    address1: '1207 boul. Brandon Est',
    city: 'Tampa',
    state: 'FL',
    country: 'USA',
    postalCode: '33511',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP23635',
  },
  {
    _id: '61b731cf8c28f88aec2b5b56',
    segmentation: 'US-LX',
    description: 'HGreg Lux',
    address: '2500 West Sample Rd.,  Pompano Beach, FL, USA, 33073',
    address1: '2500 West Sample Rd.',
    city: 'Pompano Beach',
    state: 'FL',
    country: 'USA',
    postalCode: '33073',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999999,
      },
    ],
    dealerLogicalId: 'MP15652',
  },
  {
    _id: '61b7340c71c15e164748e5e6',
    segmentation: 'US-MD',
    description: 'HGreg Medley Recon',
    address: ',  Medley, FL, USA, ',
    address1: '',
    city: 'Medley',
    state: 'FL',
    country: 'USA',
    postalCode: '',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999995,
      },
    ],
  },
  {
    _id: '44',
    segmentation: 'US-HUS',
    description: 'HGreg Houston',
    address: ',  Houston, TX, USA, ',
    address1: '',
    city: 'Houston',
    state: 'TX',
    country: 'USA',
    postalCode: '77074',
    vauto_settings: {
      access_id: '63696CD6339C874EBD010B046825674A',
      secret_key: '91WGE-XzJpO-dsBz5yprauLEbzOoAsrIi3RadAa5lcM=',
      entity_id: 'MP4450',
      username: 'MP4450',
    },
    pdf_tempates: {
      contract: {
        path: './pdf/hgregoire/contract-us.pdf',
        copy: './pdf/hgregoire/contract-us.pdf',
      },
      offer: {
        path: './pdf/hgregoire/offer-w-us.pdf',
        copy: './pdf/hgregoire/offer-us.pdf',
      },
      offerCond: {
        path: './pdf/hgregoire/offer-cond-us.pdf',
        copy: './pdf/hgregoire/offer-cond-us.pdf',
      },
      offerQuick: {
        path: './pdf/hgregoire/offer-quick-us.pdf',
      },
    },
    tax: 0.07,
    periodPrice: [
      {
        percent: 1,
        startPrice: 0,
        endPrice: 9999995,
      },
    ],
  },
];

export const CA_LOCATION = [
  'RS',
  'SE',
  'SL',
  'CHI',
  'MG',
  'RI',
  'DRU',
  'NISE',
  'NICH',
  'NIVI',
  'HYVA',
  'CRSJ',
  'IFLA',
  'MILA',
  'ABYE',
  'DEMO',
  'NR',
  'VA',
  'KIVA',
  'MIVA',
  'TOCA',
  'NIBL',
  'TRAIN',
  'VIC',
  'AM',
  'HGBL',
  'SHHO',
  'MGHO',
  'KIACH',
  'HYIP',
  'IFBL',
  'VOLTRO',
  'TR',
  'HYTR',
  'KIATR',
  'NISTR',
  'KCS',
  'KLS',
  'MTR',
  'DCH',
  'NSW',
  'GMC',
  'PDGTR',
];

export const US_LOCATION = [
  'US-MI',
  'US-OR',
  'US-MIA',
  'US-WP',
  'US-DE',
  'US-KE',
  'US-BP',
  'US-PH',
  'US-IN',
  'US-TM',
  'US-LX',
  'US-MD',
  'US-HUS',
];
