import {
  AppraisalVehByIdCoreDocument,
  useGetAppraisalMutation,
  useStartMonkaiInspectionMutation,
  useUploadAppraisalPhotoReactiveMutation,
} from 'graphql-types';
import { InspectionItem } from './types';
import { useInspectionsStore } from './inspections.store';
import { sights } from 'modules/physical-eval-edit/constants/values';
import useMonkToken from '../../../modules/physical-eval-edit/components/PicturesForm/hooks/useMonkToken';
import useUploadMonkaiImage from './useUploadMonkaiImage';
import useStore from '../../pe-edit-appraisal.store';
import { useRef } from 'react';
import { IS_MONKAI_AVAILABLE } from '../../../constants/config/monkia.config';
import { useApolloClient } from '@apollo/client';

const useUploadInspectionItem = () => {
  const [getAppraisal] = useGetAppraisalMutation();
  const [uploadAppraisalPhoto] = useUploadAppraisalPhotoReactiveMutation();
  const { removePhotos, setLoading, isInspectionStarted, getInspection } =
    useInspectionsStore();

  const client = useApolloClient();

  const [startMonkaiInspection] = useStartMonkaiInspectionMutation();
  const { handleUploadMonkaiImage } = useUploadMonkaiImage();
  const { setAppraisalPhoto } = useStore();

  const { monkToken } = useMonkToken();
  const monkRef = useRef<string | null | undefined>(null);
  monkRef.current = monkToken;

  const uploadInspectionItem = async (currentInspection: InspectionItem) => {
    const { inspectionId, photos, appraisalId } = currentInspection;

    try {
      setLoading(appraisalId, inspectionId, true);

      if (photos.length) {
        const { data } = await getAppraisal({
          variables: { id: appraisalId.toString() },
        });

        if (!data?.getAppraisal) {
          setLoading(appraisalId, inspectionId, false);
          throw new Error('Unable to fetch appraisal');
        }

        for (const photo of photos) {
          const isPhotoStillExists = data.getAppraisal.result.photos.some(
            ({ src }) => src === photo.serverSrcId
          );

          if (isPhotoStillExists) {
            await new Promise<void>((resolve) => {
              const id = setInterval(() => {
                if (monkRef.current) {
                  clearInterval(id);
                  resolve();
                }
              }, 500);
            });

            if (IS_MONKAI_AVAILABLE) {
              try {
                await handleUploadMonkaiImage(
                  inspectionId,
                  photo.picture,
                  photo.sight
                );
                // eslint-disable-next-line no-empty
              } catch (err) {}
            }

            const uploadedPhoto = await uploadAppraisalPhoto({
              variables: {
                id: appraisalId,
                file: {
                  id: appraisalId,
                  src: photo.src,
                  zone: sights[photo.sight],
                },
              },
            });

            const srcS3 =
              uploadedPhoto.data?.uploadAppraisalPhotoReactive.result.src || '';

            const updatedPhotos = data.getAppraisal.result.photos.map(
              (photoItem) =>
                photoItem.src === photo.serverSrcId
                  ? { ...photoItem, src: srcS3 }
                  : photoItem
            );

            data.getAppraisal.result.photos = updatedPhotos;

            setAppraisalPhoto({
              id: appraisalId,
              zone: sights[photo.sight],
              src: srcS3,
            });

            removePhotos(appraisalId, inspectionId, [photo.serverSrcId]);

            // UPDATE CACHE:

            const existingData = client.cache.readQuery<{
              appraisalVehByIdCore: { result: { photos: any[] } };
            }>({
              query: AppraisalVehByIdCoreDocument,
              variables: { id: appraisalId.toString() },
            });

            if (existingData) {
              client.cache.writeQuery({
                query: AppraisalVehByIdCoreDocument,
                variables: { id: appraisalId.toString() },
                data: {
                  appraisalVehByIdCore: {
                    ...existingData.appraisalVehByIdCore,
                    result: {
                      ...existingData.appraisalVehByIdCore.result,
                      photos:
                        existingData.appraisalVehByIdCore.result.photos.map(
                          (photoItem) =>
                            photoItem.zone === sights[photo.sight]
                              ? { ...photoItem, src: srcS3 }
                              : photoItem
                        ),
                    },
                  },
                },
              });
            }
          } else {
            removePhotos(appraisalId, inspectionId, [photo.serverSrcId]);
          }
        }
      }

      if (
        isInspectionStarted(appraisalId, inspectionId) &&
        !getInspection(appraisalId, inspectionId)?.photos.length
      ) {
        try {
          await startMonkaiInspection({
            variables: {
              inspectionId,
              input: {},
            },
          });
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    } catch (err) {
      throw Error('error');
    } finally {
      setLoading(appraisalId, inspectionId, false);
    }
  };

  return { uploadInspectionItem };
};

export default useUploadInspectionItem;
