import React, { FC, PropsWithChildren } from 'react';
import ShadowBox from '../../../../../ui/layouts/ShadowBox';
import { useTranslation } from 'react-i18next';

type Props = PropsWithChildren & {
  skip?: boolean;
};

const DetailsWrapper: FC<Props> = ({ children, skip }) => {
  const { t } = useTranslation();

  if (skip) return <>{children}</>;

  return (
    <ShadowBox
      title={t('appraisal.data-by-win')}
      titleProps={{ color: 'green.800' }}
    >
      {children}
    </ShadowBox>
  );
};

export default DetailsWrapper;
