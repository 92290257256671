import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { Wrapper, Header } from './ShadowBox.styled';
import { Box, StackProps, Typography, TypographyProps } from '@mui/material';
import { useTranslation } from 'react-i18next';

export type ShadowBoxProps = PropsWithChildren & {
  title?: string;
  extension?: ReactNode;
  wrapperProps?: StackProps;
  headerProps?: StackProps;
  titleProps?: TypographyProps;
};

const ShadowBox: FC<ShadowBoxProps> = ({
  title,
  children,
  wrapperProps,
  headerProps,
  titleProps,
  extension,
}) => {
  const { t } = useTranslation();
  const isHeaderShown = Boolean(title?.trim()) || Boolean(extension);

  return (
    <Wrapper {...wrapperProps}>
      {isHeaderShown && (
        <Header {...headerProps}>
          {title && (
            <Typography variant="h4" {...titleProps}>
              {t(title)}
            </Typography>
          )}

          <Box flex={1} />

          {extension}
        </Header>
      )}

      {children}
    </Wrapper>
  );
};

export default ShadowBox;
