import React from 'react';
import { CircularProgress, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { RoutesConfig } from 'router/routes.config';
import useDebounceFalse from '../../../../hooks/useDebounceFalse';
import { useProcessingQueue } from '../../../../contexts/OnlineStatusProvider/OnlineStatusProvider';
import { OpenInNew } from '@mui/icons-material';

const UploadingBar = () => {
  const { t } = useTranslation();
  const { isProcessingQueue } = useProcessingQueue();

  const debouncedProcessing = useDebounceFalse(isProcessingQueue, 1200);

  if (!debouncedProcessing) return null;

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      sx={{ bgcolor: 'green.700', px: 2, py: 1, gap: 2 }}
    >
      <Stack gap={2} direction="row" alignItems="center">
        <CircularProgress size="20px" sx={{ color: 'white', minWidth: 20 }} />

        <Typography
          color="white"
          variant="body1"
          sx={{ whiteSpace: 'pre-wrap', fontWeight: 700 }}
        >
          {t('offline.local-uploading')}
        </Typography>
      </Stack>

      <NavLink to={RoutesConfig.Profile.LocalChanges}>
        <OpenInNew sx={{ color: 'white' }} />
      </NavLink>
    </Stack>
  );
};

export default UploadingBar;
