import { useState, useEffect } from 'react';

function useDebounceFalse(value: boolean, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    // Only debounce when value becomes false
    if (!value) {
      const handler = setTimeout(() => {
        setDebouncedValue(value);
      }, delay);

      // Cleanup timeout if value changes before the delay completes
      return () => {
        clearTimeout(handler);
      };
    } else {
      // Immediately update the value when it becomes true
      setDebouncedValue(value);
    }
  }, [value, delay]);

  return debouncedValue;
}

export default useDebounceFalse;
