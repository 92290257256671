import React, { FC, memo } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import { MenuItem, TextField, TextFieldProps } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { IOption } from 'constants/entities/ui/types';

type Props = Omit<ControllerProps, 'render' | 'control' | 'disabled'> &
  TextFieldProps & {
    label: string;
    changeHandler?: (value: string) => false | void | undefined;
    options?: IOption[];
    menuHeight?: number;
  };

const TextFieldController: FC<Props> = ({
  name,
  rules,
  defaultValue,
  shouldUnregister,
  fullWidth = true,
  label,
  helperText,
  changeHandler,
  placeholder,
  options,
  menuHeight = 250,
  SelectProps,
  onBlur: onOutterBlur,
  ...textFieldProps
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      control={control}
      render={({
        field: { onChange, value, ref, onBlur },
        fieldState: { error },
      }) => (
        <TextField
          ref={ref}
          value={value}
          onBlur={(e) => {
            onBlur();
            onOutterBlur?.(e);
          }}
          fullWidth={fullWidth}
          label={t(label)}
          error={!!error?.message}
          helperText={t((error?.message ?? helperText ?? '') as string)}
          placeholder={placeholder ? t(placeholder as string) : undefined}
          SelectProps={{
            MenuProps: {
              sx: {
                '& .MuiPaper-root': { maxHeight: menuHeight },
              },
            },

            onClose: () => {
              if (!SelectProps?.multiple) {
                setTimeout(() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  document.activeElement?.blur();
                }, 0);
              }
            },

            ...SelectProps,
          }}
          onChange={(event) => {
            const preventChanging = changeHandler?.(event.target.value);

            if (preventChanging !== false) {
              onChange(event);
            }
          }}
          {...textFieldProps}
        >
          {options?.map((option) => (
            <MenuItem
              key={option.value}
              value={option.value}
              data-cy={`item-${option.value}`}
            >
              {t(option.label)}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  );
};

export default memo(TextFieldController);
