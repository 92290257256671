import { TakePictureArg } from 'modules/physical-evaluation/components/CameraWebNew';
import { useToggle } from '../../../../../hooks';

type Options = {
  sightIds: string[];
  sights: any[];
  uploads: any[];
  onPicture: (arg: TakePictureArg) => Promise<void>;
};

const useAddPictureHandler = ({ onPicture }: Options) => {
  const [isLoading, loadingControls] = useToggle();

  const handlePress = async (state: any, api: any) => {
    try {
      loadingControls.on();

      const pictureResult = await api.takePictureAsync();

      api.setPictureAsync(pictureResult);

      onPicture({
        picture: pictureResult,
        sight: state.sights.state.current.id,
      });
    } finally {
      loadingControls.off();

      api.goNextSight();
    }
  };

  return { handlePress, isLoading };
};

export default useAddPictureHandler;
