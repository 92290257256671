import { appraisalOrigins } from '../../../constants/entities/appraisal';
import { RoutesConfig } from 'router/routes.config';
import { useStartMonkaiInspectionMutation } from 'graphql-types';
import useStore from 'store/pe-edit-appraisal.store';
import { useNavigate } from 'react-router-dom';
import { useFeedback } from 'hooks/useFeedback';
import { useUpdatePhysicalEvaluation } from 'hooks/useUpdatePhysicalEvaluation';
import { useInspectionsStore } from '../../../store/offline-changes/inspections/inspections.store';
import { IS_MONKAI_AVAILABLE } from '../../../constants/config/monkia.config';

const useSubmitEval = (refetch: () => void) => {
  const navigate = useNavigate();
  const { appraisal } = useStore();
  const { showSupport } = useFeedback();
  const { isInspectionOffline, forceRemoveInspection, startInspection } =
    useInspectionsStore();

  const [startMonkaiInspection, { loading: isInspectionStarting }] =
    useStartMonkaiInspectionMutation();

  const { updateFn: updateAppraisalFn, loading: isLoadingPictures } =
    useUpdatePhysicalEvaluation();

  const handleSubmit = async () => {
    if (!appraisal || !appraisal._id) {
      return showSupport('pe2321');
    }

    const appraisalOrigin =
      appraisalOrigins.find((it) => it.id === appraisal.origin) ||
      appraisalOrigins[2];

    let origin = appraisalOrigins.find((it) => it.id === appraisal.origin)?.id;
    if (!appraisal.origin || appraisal.origin.length === 0) {
      origin = appraisalOrigins[2].id; // if user not set origin we set default with inventory
    }

    await updateAppraisalFn({
      variables: {
        id: appraisal._id,
        input: {
          change: {
            origin,
            leaseReturn: appraisalOrigin.appraisal.leaseReturn,
            vehicleType: appraisalOrigin.appraisal.vehicleType,
            status: 'evaluation',
            isPhotosCertifyUser: true,
          },
          socket: true,
        },
      },
    });

    if (!appraisal.monkaiInspectionId) {
      return IS_MONKAI_AVAILABLE ? showSupport('pe4323') : null;
    }

    if (isInspectionOffline(appraisal.id ?? 0, appraisal.monkaiInspectionId)) {
      startInspection(appraisal.id ?? 0, appraisal.monkaiInspectionId);
    } else {
      if (IS_MONKAI_AVAILABLE) {
        try {
          await startMonkaiInspection({
            variables: {
              input: {},
              inspectionId: appraisal.monkaiInspectionId,
            },
          });
          // eslint-disable-next-line no-empty
        } catch (error) {}
      }

      forceRemoveInspection(appraisal.id ?? 0, appraisal.monkaiInspectionId);
    }

    refetch();
    return navigate(RoutesConfig.PhysicalEvaluation.List);
  };

  return {
    handleSubmit,
    isLoadingPictures: isLoadingPictures || isInspectionStarting,
  };
};

export default useSubmitEval;
