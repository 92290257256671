import useStore from 'store/pe-edit-appraisal.store';
import {
  useNewBlackBookDecoderQuery,
  useNewBlackBookDetailDecoderLazyQuery,
} from 'graphql-types';
import { useEffect, useMemo } from 'react';
import { IOption } from '../../../../../constants/entities/ui/types';
import { useFormContext, useWatch } from 'react-hook-form';
import { PeVehicleForm } from 'constants/forms';
import { VehicleDetailKeys } from '../../../../physical-eval-edit/constants/values';

const useBlackBookController = () => {
  const { appraisal } = useStore();

  const { setValue } = useFormContext<PeVehicleForm.FormType>();

  const selectedSeries = useWatch<PeVehicleForm.FormType>({
    name: PeVehicleForm.Keys.Series,
  });

  const selectedStyle = useWatch<PeVehicleForm.FormType>({
    name: PeVehicleForm.Keys.BodyType,
  });

  /*
   * Fetches the vehicle information from the VIN
   */
  const { data: decodeVinInfo, loading: isDecodeLoading } =
    useNewBlackBookDecoderQuery(
      appraisal?.vin ? { variables: { vin: appraisal?.vin } } : { skip: true }
    );

  const series = decodeVinInfo?.newBlackBookDecoder?.trims ?? [];
  const isBasedOnVin = series.length === 1 && series[0]?.styles?.length <= 1;

  const [fetchStyles, { data: stylesData, loading: isFetchingStyles }] =
    useNewBlackBookDetailDecoderLazyQuery({
      onCompleted: ({ newBlackBookDetailDecoder }) => {
        setValue(
          PeVehicleForm.Keys.EngineCylinderLabel,
          newBlackBookDetailDecoder?.engine ?? ''
        );

        setValue(
          PeVehicleForm.Keys.EngineCylinderCount,
          newBlackBookDetailDecoder?.engineCylinderCount ?? ''
        );

        setValue(
          PeVehicleForm.Keys.Traction,
          newBlackBookDetailDecoder?.traction ?? ''
        );

        setValue(
          PeVehicleForm.Keys.TransmissionType,
          newBlackBookDetailDecoder?.transmission ?? ''
        );
      },
    });

  /*
   * Fetches the vehicle style details.
   * Call 'fetchStyleDetails' when the user selects a style from the dropdown.
   */
  const [fetchStyleDetails, { loading: isDetailsLoading }] =
    useNewBlackBookDetailDecoderLazyQuery({
      onCompleted: ({ newBlackBookDetailDecoder }) => {
        setValue(
          PeVehicleForm.Keys.EngineCylinderLabel,
          newBlackBookDetailDecoder?.engine ?? ''
        );

        setValue(
          PeVehicleForm.Keys.EngineCylinderCount,
          newBlackBookDetailDecoder?.engineCylinderCount ?? ''
        );

        setValue(
          PeVehicleForm.Keys.Traction,
          newBlackBookDetailDecoder?.traction ?? ''
        );

        setValue(
          PeVehicleForm.Keys.TransmissionType,
          newBlackBookDetailDecoder?.transmission ?? ''
        );
      },
    });

  useEffect(() => {
    if (isBasedOnVin) {
      if (!selectedSeries) {
        setValue(PeVehicleForm.Keys.Series, series[0].value || series[0].label);
      }

      if (series[0].styles?.length === 1) {
        if (!selectedStyle) {
          setValue(
            PeVehicleForm.Keys.BodyType,
            series[0]?.styles?.[0]?.value || series[0]?.styles?.[0]?.label
          );
        }
      }
    }
  }, [isBasedOnVin]);

  const seriesOptions = useMemo<IOption[]>(() => {
    return (
      decodeVinInfo?.newBlackBookDecoder?.trims?.map(
        ({ value, styles, label }) => ({
          value: value || label,
          label: value || label,
          styles,
        })
      ) ?? []
    );
  }, [decodeVinInfo]);

  const stylesList = useMemo(() => {
    if (decodeVinInfo?.newBlackBookDecoder?.trims?.[0].styles?.length === 1) {
      return decodeVinInfo?.newBlackBookDecoder?.trims?.[0].styles;
    }

    return (
      stylesData?.newBlackBookDetailDecoder.trims.find(
        (series) => series.value === selectedSeries
      )?.styles ?? []
    );
  }, [stylesData, selectedSeries, decodeVinInfo, isBasedOnVin]);

  const bodyTypeOptions = useMemo<IOption[]>(() => {
    return stylesList.map(({ value }: any) => ({ value, label: value }));
  }, [stylesList]);

  const handleSeriesChange = (newSeries: string) => {
    setValue(PeVehicleForm.Keys.StyleId, '');
    setValue(PeVehicleForm.Keys.BodyType, '');
    VehicleDetailKeys.forEach(({ formKey }) => setValue(formKey, ''));

    const id =
      decodeVinInfo?.newBlackBookDecoder?.trims?.find(
        ({ value }: any) => value === newSeries
      )?.label ?? '';

    fetchStyles({
      variables: { id: id || newSeries, series: id || newSeries },
    });
  };

  const handleBodyTypeChange = (newType: string) => {
    const id = stylesList.find(({ value }: any) => value === newType)?.label;

    if (!id) return;

    setValue(PeVehicleForm.Keys.StyleId, id);
    VehicleDetailKeys.forEach(({ formKey }) => setValue(formKey, ''));

    fetchStyleDetails({ variables: { id, series: id } });
  };

  const isAbleToGetMoreDetails = !!seriesOptions.length && !selectedSeries;

  return {
    seriesOptions,
    bodyTypeOptions,
    selectedSeries,
    selectedStyle,

    handleSeriesChange,
    handleBodyTypeChange,

    isAbleToGetMoreDetails,

    isDecodeLoading,
    isDetailsLoading: isFetchingStyles || isDetailsLoading,
  };
};

export default useBlackBookController;
