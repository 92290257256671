import { useProcessingQueue } from 'contexts/OnlineStatusProvider/OnlineStatusProvider';
import { useConditionStore } from './conditions/conditions.store';
import { useEffect, useRef } from 'react';
import useUploadConditionItem from './conditions/useUploadConditionItem';
import { ConditionPhotosItem } from './conditions/types';
import { useInspectionsStore } from './inspections/inspections.store';
import { InspectionItem } from './inspections/types';
import useUploadInspectionItem from './inspections/useUploadInspectionItem';

const maxRetries = 2;

const useSyncDB = () => {
  const conditions = useConditionStore();
  const inspections = useInspectionsStore();
  const { isProcessingQueue, setProcessingQueue } = useProcessingQueue();

  const conditionsRef = useRef<ConditionPhotosItem[]>([]);
  conditionsRef.current = conditions.conditionPhotos;

  const inspectionsRef = useRef<InspectionItem[]>([]);
  inspectionsRef.current = inspections.inspections;

  const { uploadConditionItem } = useUploadConditionItem();
  const { uploadInspectionItem } = useUploadInspectionItem();

  const isProcessingQueueRef = useRef(false); // Flag to prevent multiple queue processes
  isProcessingQueueRef.current = isProcessingQueue;

  const uploadDBPhotos = async (item: ConditionPhotosItem | InspectionItem) => {
    let attempt = 0;

    while (attempt < maxRetries) {
      try {
        attempt++;

        if (Object.hasOwn(item, 'conditionId')) {
          await uploadConditionItem(item as ConditionPhotosItem);

          conditions.removeCondition(
            item.appraisalId,
            (item as ConditionPhotosItem).conditionId
          );
        }

        if (Object.hasOwn(item, 'inspectionId')) {
          if (item.photos) {
            await uploadInspectionItem(item as InspectionItem);
          }

          const isNewPhotos = inspectionsRef.current.find(
            ({ appraisalId, inspectionId }) =>
              item.appraisalId == appraisalId &&
              (item as any).inspectionId === inspectionId
          )?.photos.length;

          if (!isNewPhotos) {
            inspections.removeInspection(
              item.appraisalId,
              (item as InspectionItem).inspectionId
            );
          }
        }

        return;
      } catch (error) {
        if (attempt >= maxRetries) {
          // Do nothing, condition remains in Zustand store and will be retried
          return;
        }

        // Trying again...
      }
    }
  };

  const processNextDBItem = async () => {
    const nextCondition = conditionsRef.current[0];
    const nextInspection = inspectionsRef.current[0];

    const nextItem = nextInspection || nextCondition;

    if (isProcessingQueueRef.current || !nextItem) {
      return; // Prevent multiple uploads running concurrently
    }

    setProcessingQueue(true);

    try {
      await uploadDBPhotos(nextItem);
    } finally {
      setProcessingQueue(false); // Reset flag after processing

      setTimeout(() => {
        processNextDBItem();
      }, 1000);
    }
  };

  useEffect(() => {
    processNextDBItem();
  }, [conditions.conditionPhotos, inspections.inspections]);
};

export default useSyncDB;
