import { useEffect, useRef } from 'react';
import { useOnlineStore } from '../OnlineStatusProvider';
import { SPEED_TEST_INTERVAL } from 'constants/config/app-variables';
import useCheckSpeedAbilities from '../hooks/useCheckSpeedAbilities';
import useRequestStore from '../../../store/requests.store';
import useIsOfflinePage from '../../../hooks/offline/useIsOfflinePage';

const NetworkStatusDetection = () => {
  const {
    setOnline,
    isSpeedTestLoading,
    setUploadImageAbility,
    isSpeedTestCompleted,
    setSpeedTestCompleted,
  } = useOnlineStore(
    ({
      setOnline,
      isSpeedTestLoading,
      setUploadImageAbility,
      isSpeedTestCompleted,
      setSpeedTestCompleted,
    }) => ({
      setOnline,
      isSpeedTestLoading,
      setUploadImageAbility,
      isSpeedTestCompleted,
      setSpeedTestCompleted,
    })
  );

  const { checkSpeedAbilities } = useCheckSpeedAbilities();
  const isOfflinePage = useIsOfflinePage();

  const loadingRef = useRef<boolean>(false);
  loadingRef.current = isSpeedTestLoading;

  const timeoutIdRef = useRef<NodeJS.Timeout | null>(null);
  const { operations } = useRequestStore();

  const requestsCountRef = useRef(0);
  requestsCountRef.current = operations.length;

  useEffect(() => {
    const handleOffline = () => {
      setOnline(false);
      setUploadImageAbility(false);
    };

    const handleOnline = () => {
      setOnline(true);

      setTimeout(checkSpeedAbilities, 10);
    };

    window.addEventListener('offline', handleOffline);
    window.addEventListener('online', handleOnline);

    return () => {
      window.removeEventListener('offline', handleOffline);
      window.removeEventListener('online', handleOnline);
    };
  }, []);

  const startSpeedAbilitiesTest = async () => {
    await new Promise<void>((resolve) => {
      const id = setInterval(() => {
        if (requestsCountRef.current === 0) {
          resolve();
          clearInterval(id);
        }
      }, 3000);
    });

    const isSuccessfullyTestedSpeed = await checkSpeedAbilities();

    if (isSuccessfullyTestedSpeed) {
      setSpeedTestCompleted(true);
    }
  };

  const runChecking = async () => {
    await startSpeedAbilitiesTest();

    timeoutIdRef.current = setTimeout(runChecking, SPEED_TEST_INTERVAL);
  };

  useEffect(() => {
    if (!isOfflinePage) {
      startSpeedAbilitiesTest();
    }

    return () => {
      if (timeoutIdRef.current) clearInterval(timeoutIdRef.current);
    };
  }, [isOfflinePage]);

  useEffect(() => {
    if (isOfflinePage) {
      if (timeoutIdRef.current) clearInterval(timeoutIdRef.current);
      runChecking();
    }

    return () => {
      if (timeoutIdRef.current) clearInterval(timeoutIdRef.current);
    };
  }, [isOfflinePage]);

  return null;
};

export default NetworkStatusDetection;
